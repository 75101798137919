.services{
width: 100%;

display: flex;
flex-direction: column;
align-items: center;
}



.services h1{
  font-size: 2rem;
  margin: 2rem 0;
  background: linear-gradient(to right, #f6a921, #cd2855);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 2px;
  font-size: 45px;
  font-weight: 400;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin:25px 20px;
  width: 100%;

  
}

.card {
    position: relative;
    display: flex;
     width: 440px;
    height: 250px;
   
    border-radius: 14px;
    z-index: 1111;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
    
  }
  
  .bg {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 430px;
    height: 240px;
    z-index: 2;
    background: rgba(255, 255, 255, .95);
    backdrop-filter: blur(24px);
    border-radius: 10px;
    overflow: hidden;
    outline: 2px solid white;
  }
.bg-div{
    display: flex;
    width: auto;
    height: 100%;
 
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin: 0 20px;
}
.bg-div img{
    height: 50px;
  
}

.card-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
   margin: 15px 0 15px 5px ;
  }


  .blob {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: #ff0000;
    opacity: 1;
    filter: blur(12px);
    animation: blob-bounce 5s infinite ease;
  }
  
  @keyframes blob-bounce {
    0% {
      transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
  
    25% {
      transform: translate(-100%, -100%) translate3d(100%, 0, 0);
    }
  
    50% {
      transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
    }
  
    75% {
      transform: translate(-100%, -100%) translate3d(0, 100%, 0);
    }
  
    100% {
      transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
  }
  .services-title{
    width: 60%;
    font-size: 21px;
    font-weight: 500;
    color: black;
    text-align: center;
  
    position: relative; /* Animasyonun pozisyonunu ayarlamak için gerekli */
    opacity: 0; /* Başlangıçta görünmez */
    animation: moveDown 2s forwards;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 500px) {

    .services h1{
      font-size: 1.5rem;
      margin: 2rem 0;
      background: linear-gradient(to right, #f6a921, #cd2855);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: 2px;
      font-size: 45px;
      font-weight: 400;
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      margin:25px 20px;
      width: 100%;
     

      
    }
    .services-title{
      width: 90%;
      font-size: 15px;
      font-weight: 500;
      color: black;
      text-align: center;
  
      position: relative; /* Animasyonun pozisyonunu ayarlamak için gerekli */
      opacity: 0; /* Başlangıçta görünmez */
      animation: moveDown 2s forwards;
      margin-bottom: 25px;
    }
    .card {
        position: relative;
        display: flex;
         width: 340px;
        height: 250px;
       
        border-radius: 14px;
        z-index: 1111;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      
        
      }
      
      .bg {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 330px;
        height: 240px;
        z-index: 2;
        background: rgba(255, 255, 255, .95);
        backdrop-filter: blur(24px);
        border-radius: 10px;
        overflow: hidden;
        outline: 2px solid white;
      }
    .bg-div{
        display: flex;
        width: auto;
        height: 100%;
     
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin: 0 20px;
        opacity: 1;
    }
    .bg-div img{
        height: 50px;
      
    }
    
    .card-title {
        font-size: 1.5rem;
        font-weight: 700;
        color: #000;
       margin: 15px 0 15px 5px ;
      }
    
    
      .blob {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        width: 190px;
        height: 180px;
        border-radius: 50%;
        background-color: #ff0000;
        opacity: 1;
        filter: blur(12px);
        animation: blob-bounce 5s infinite ease;
      }
      .services h1{
        font-size: 1.5rem;
   
      
      }
  }