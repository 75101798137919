.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-image: linear-gradient(to right, #ffbc00, #ff0058);
  flex-direction: column;
}
.footer-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  font-size: 25px;
  align-items: center;
  justify-content: center;
  color: white;

  color: white;
  margin: 25px 0px;
}
.footer-text {
  text-align: center;

  color: white;
  margin: 25px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.8px;
}
.footer-name img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 10px;
  color: white;
}
.footer-name span {
  color: white;
  font-size: 35px;
  font-weight: 400;
  margin-left: 15px;
  margin-top: 10px;
  letter-spacing: 1.2px;
}
.footer-social {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
margin: 30px 0px;
  flex-direction: row;
}
.footer-social-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0 10px;
}
.footer-socail-icon {
  width: 80px;
  color: white;
  font-size: 40px;
}
@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
  }
  .footer-name {
    font-size: 20px;
  }
  .footer-text {
    font-size: 15px;
  }
  .footer-name img {
    width: 40px;
    height: 40px;
  }
  .footer-name span {
    font-size: 25px;
  }
  .footer-social-box {
    margin: 0 5px;
  }
  .footer-socail-icon {
    font-size: 30px;
    width: 50px;
  }
  
}