.Appbar{
    width: 100%;
    height: 9vh;
    background-image: linear-gradient(to right, #f6a921, #cd2855);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 10px rgb(156, 146, 146);
    z-index: 10000;
    position: fixed;

}
.main-logo{
    display: flex;
    color: white;
    font-size: 55px;
    margin-left: 10%;
    font-weight: 300;
}
.main-logo-text{
display: flex;
margin-bottom: 10px;

}
.main-logo-small{
    display: none;
}

@media screen and (max-width: 800px)   {
    .main-logo{
        display: none;
    }
   
    
    .main-logo-small{
        display: flex;
        color: white;
        font-size: 45px;
        margin-left: 1%;
        font-weight: 300;
    }
}
.menu{
    display: flex;
    flex-direction: row;
    margin-right: 10%;
   
   }
.Appbutton {
    padding: 1em 1.2em;
    margin: 0 10px;
    display: block;
   background-color: transparent;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    transition: all 1000ms;
    font-size: 1.rem;
    position: relative;
    overflow: hidden;
    border: 1px solid white;
    border-radius: 10px;
    
  }
  
  .Appbutton:hover {
    color:rgb(255, 81, 0);
    transform: scale(1.1);
    outline: 3px solid white;
    box-shadow: 4px 5px 17px -4px red;
  }
  
  .Appbutton::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
  background-color: white;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
  }
  
 .Appbutton:hover::before {
    width: 250%;
  }
  .small-menu{
    display: none;

  }

  @media screen and (max-width: 1074px) {
    .menu{
      display: none;
    }
    .small-menu{
      display: flex;
      flex-direction: row;
      margin-right: 5%;
    }
    .Appbutton{
      margin: 0 5px;
    }
    
  }
  .menu-button{
  border: none;
    background: none;
    color: white;
  }

  .burger {
  position: relative;
  width: 40px;
  height: 30px;
  background: transparent;
  cursor: pointer;
  display: block;
}

.burger input {
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.burger span:nth-of-type(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  transform-origin: left center;
  transform: translateY(-50%);
}

.burger input:checked ~ span:nth-of-type(1) {
  top: 0;
  left: 5px;
  transform: rotate(405deg);
}

.burger input:checked ~ span:nth-of-type(2) {
  width: 0%;
  opacity: 0;
}

.burger input:checked ~ span:nth-of-type(3) {
  top: 28px;
  left: 5px;
  transform: rotate(-405deg);
}

  
  #menuButton {
    position: fixed;
    top: 15px;
    left: 15px;
    font-size: 20px;
    z-index: 1;
  }
  
  #main {
    transition: margin-right 0.5s;
    padding: 16px;
  }
  
  #main.open {
    margin-right: 250px;
  }
  