.projects {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects h1 {
  font-size: 2rem;
  margin: 2rem 0;
  background: linear-gradient(to right, #f6a921, #cd2855);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 2px;
  font-size: 45px;
  font-weight: 400;
}

.projects-title {
  width: 60%;
  font-size: 21px;
  font-weight: 500;
  color: black;
  text-align: center;

  position: relative; /* Animasyonun pozisyonunu ayarlamak için gerekli */
  opacity: 0; /* Başlangıçta görünmez */
  animation: moveDown 2s forwards;
  margin-bottom: 40px;
}
.projects-contents {
  width: auto;
  height: auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px 20px;
}
.project-card {
  position: relative;
  width: 603px;
  height: 453px;
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
  margin: 15px 20px;
  padding: 1px;
}

.project-card:hover {
  transform: translateY(-20px);
}

.project-card::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(45deg, #ffbc00, #ff0058);
  border-radius: 1.2em;
}

.project-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ffbc00, #ff0058);
  filter: blur(30px);
}

.project-card span {
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  border-radius: 1em;
}

.project-card span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

.project-card .content {
  position: relative;
  padding: 10px;
  z-index: 10;
  width: 594px;
  height: 444px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  border-radius: 15px;
  font-weight: 800;
  font-size: 1.5em;
  background-color: white;
  margin: 3px;
}
.project-img {
  width: 80%;
  height: 55%;
  border-radius: 15px;
  background-color: red;
}
.project-img img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.project-title {
  font-size: 1.3em;
  font-weight: 600;
  color: black;
  margin: 10px 0;
}
.project-description {
  font-size: 0.65em;
  font-weight: 500;
  color: black;
  margin: 10px 20px;
  text-align: center;
}

.more-button {
  border: none;
  outline: none;
  padding: 15px 25px;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  width: 250px;
  height: 60px;
  color: #ec4f1f;
  font-size: 19px;
  background: linear-gradient(to right, #f6a921, #cd2855);
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  opacity: 0; /* Başlangıçta görünmez */
  animation: moveUp 2s forwards;
  margin-bottom: 20px;
  animation-delay: 1s;
}

.more-button:hover {
  color: white;
}
.more-button::before {
  content: "";

  position: absolute;

  left: 3px;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border-radius: 25px;
  background-color: #f0dbe0 ;
  z-index: -1;
  transition: 200ms;
}
.more-button::after {
  font-size: 16px;
  background: linear-gradient(to left, #00ffa3, #dc1fff);
  -webkit-background-clip: text;
  color: white;
  transition: 200ms;
}
.more-button:hover::before {
  opacity: 10%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  color: white;
}
.more-button:hover::after {
  color: white;
}
@media screen and (max-width: 610px) {
  .projects h1 {
    font-size: 1.5rem;
    margin: 2rem 0;
    background: linear-gradient(to right, #f6a921, #cd2855);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-size: 45px;
    font-weight: 400;
  }

  .projects-title {
    width: 90%;
    font-size: 15px;
    font-weight: 500;
    color: black;
    text-align: center;

    position: relative; /* Animasyonun pozisyonunu ayarlamak için gerekli */
    opacity: 0; /* Başlangıçta görünmez */
    animation: moveDown 2s forwards;
    margin-bottom: 25px;
  }
  .projects-contents {
    width: 100%;
    height: auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 5px 20px;
  }
  .project-card {
    position: relative;
    width: 350px;
    height: 400px;
    color: #fff;
    transition: 0.5s;
    cursor: pointer;
    margin: 25px 20px;
  }

  .project-card:hover {
    transform: translateY(-20px);
  }

  .project-card::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(45deg, #ffbc00, #ff0058);
    border-radius: 1.2em;
  }

  .project-card::after {
    content: "";
    position: absolute;
    margin-top: 5px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #ffbc00, #ff0058);
    filter: blur(30px);
  }

  .project-card span {
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
    border-radius: 1em;
  }

  .project-card span::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .project-card .content {
    position: relative;
    padding: 10px;
    z-index: 10;
    width: 342px;
    height: 392px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-radius: 15px;
    font-weight: 800;
    font-size: 1.5em;
    background-color: white;
    margin: 4px;
  }
  .project-img {
    width: 90%;
    height: 45%;
    border-radius: 15px;
    background-color: red;
  }
  .project-img img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
  .project-title {
    font-size: 1em;
    font-weight: 600;
    color: black;
    margin: 7px 0;
  }
  .project-description {
    width: 100%;
    font-size: 0.55em;
    font-weight: 500;
    color: black;

    text-align: center;
  }
  .projects h1 {
    font-size: 1.5rem;
  }
  .more-button::before {
    content: "";
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
    border-radius: 25px;
    background-color: #f0dbe0;
    z-index: -1;
    transition: 200ms;
  }
  .more-button {
    position: relative; /* Animasyonun pozisyonunu ayarlamak için gerekli */

    width: 125px;
    height: 50px;
    padding: 8px 15px;
    font-size: 15px;
  }

}
@media screen and (max-width: 610px) {
}