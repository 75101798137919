.communicate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
}
.communicate h1 {
  font-size: 2rem;
  margin: 2rem 0;
  background: linear-gradient(to right, #f6a921, #cd2855);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 2px;
  font-size: 45px;
  font-weight: 400;
}
.communicate-title {
  width: 60%;
  font-size: 21px;
  font-weight: 500;
  color: black;
  text-align: center;

  position: relative; /* Animasyonun pozisyonunu ayarlamak için gerekli */
  opacity: 0; /* Başlangıçta görünmez */
  animation: moveDown 2s forwards;
  margin-bottom: 40px;
}
.communicate-div {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  flex-direction: row;
  margin-bottom: 20px;
}
.communicate-right {
  width: 43%;
  height: 85%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  z-index: 10;
  min-width: 530px;
}
.communicate-left {
  width: 43%;
  min-width: 600px;
  height: auto;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-div {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  flex-direction: row;
}
.com-title-div {
  display: flex;
  background-color: transparent;
  width: 380px;
  height: 155px;
  justify-content: center;

  align-items: center;
  margin: 10px 15px;
}
.com-title-div:hover {
  cursor: pointer;
  transform: translateY(-10px);
}

.com-title-icon {
  width: 80px;

  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-image: linear-gradient(to top right, #f6a921, #cd2855);
}
.com-title-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 30px 0px 30px 25px;

  width: 70%;
  height: 100%;
}
.title-div a{
  text-decoration: none;
  color: black;

}
.com-title-text h4 {
  font-size: 1.8rem;
  font-weight: 700;
  color: black;
}
.com-title-text span {
  font-size: 1.3rem;
  font-weight: 400;
  color: grey;
}

.map-card {
  position: relative;
  width: 500px;
  height: 350px;
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
  margin: 15px 20px;
}

.map-card:hover {
  transform: translateY(-20px);
}

.map-card::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(45deg, #ffbc00, #ff0058);
  border-radius: 1.2em;
}

.map-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ffbc00, #ff0058);
  filter: blur(30px);
}

.map-card .content {
  position: relative;

  z-index: 10;
  width: 492px;
  height: 342px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  border-radius: 15px;
  font-weight: 800;
  font-size: 1.5em;
  background-color: white;
  margin: 4px;
}
.map-img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: transparent;
}
.map-img img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: transparent;
}
.right-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #000;
  margin: 15px 0 15px 15px;
}
.name-mail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 19%;
  background-color: transparent;
  flex-direction: row;
}
.error-div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  flex-direction: column;

}
.error{
font-size: 0.9rem;
font-weight: 700;
color: red; 

}
.form {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.InputContainer {
  width: 43%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #f6a921, #cd2855);
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  padding: 3px;
  margin: 20px 15px;
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  caret-color: rgb(255, 81, 0);
  background-color: rgb(255, 255, 255);
  border-radius: 7px;
  padding-left: 15px;
  letter-spacing: 0.8px;
  color: rgb(19, 19, 19);
  font-size: 16px;
}

.message-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 185px;
  background-color: transparent;
}
.message {
  width: 90%;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #f6a921, #cd2855);
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  padding: 3px;
  margin: 0 15px;
}
#textarea {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;

  background-color: rgb(255, 255, 255);
  border-radius: 7px;
  padding-left: 15px;
  letter-spacing: 0.8px;
  color: rgb(19, 19, 19);
  font-size: 16px;
  padding: 15px 5px 5px 15px;
  resize: none;
}
.submitted-animation {
  font-size: 1.2em;
  color: green;
  animation: fadeIn 0.5s ease-in-out;

  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;


}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#textarea::placeholder {
  color: #888;
  font-size: 16px;
  margin-top: 40px;
}
.submit-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  border-radius: 7px;
  height: 45px;
  background-color: transparent;
  margin: 10px 0;
}
#submit-button {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #f6a921, #cd2855);
  border-radius: 7px;
  border: none;
  font-size: 21px;
  letter-spacing: 2px;
  font-weight: 700;
  color: white;
}
#submit-button:hover {
  background-image: linear-gradient(to right, #f6a921, #cd2855);
  cursor: pointer;
  transform: scale(1.1);
}
@media screen and (max-width: 800px) {
  .title-div {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    flex-direction: column;
  }
  .communicate h1 {
    font-size: 1.7rem !important;
    margin: 1rem 0;

    letter-spacing: 2px;
    font-size: 45px;
    font-weight: 400;
  }
  .communicate-title {
    width: 85%;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .com-title-div {
    height: auto;
    justify-content: center;

    align-items: center;
    margin: 5px 10px;
  }
  .com-title-icon {
    width: 60px;
  }
  .com-title-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding: 15px 0px 15px 25px;

    width: 70%;
    height: 100%;
  }
  .com-title-text h4 {
    font-size: 1.5rem;
  }
  .com-title-text span {
    font-size: 1.1rem;
  }
  .communicate-div {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    flex-direction: column-reverse;
  }
  .communicate-right {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;

    z-index: 10;
    min-width: auto;
  }
  .communicate-left {
    width: 43%;
    min-width: auto;
  
  }
  .name-mail {
    width: 100%;
    height: auto;
    margin: 0;
    background-color:transparent;
  align-items: center;
  justify-content: space-around; 
  padding: 0 20px;

}
  .right-title{
    font-size: 1.5rem;
    margin: 15px 10px 5px 10px;
  }
  .InputContainer {
    min-width: 160px;
    width: 40%;
    padding: 3px;
    margin: 10px 0px 10px 0;
  }
  #textarea {
    
  
    background-color: rgb(255, 255, 255);
    border-radius: 7px;
    padding-left: 15px;
    letter-spacing: 0.8px;
    color: rgb(19, 19, 19);
    font-size: 16px;
    padding: 5px 8px;
    resize: none;
  }
  
.map-card {
  position: relative;
  width: 350px;
  height: 190px;
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
  margin: 15px 20px;
}

.map-card:hover {
  transform: translateY(-20px);
}

.map-card::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(45deg, #ffbc00, #ff0058);
  border-radius: 1.2em;
}

.map-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ffbc00, #ff0058);
  filter: blur(30px);
}

.map-card .content {
  position: relative;

  z-index: 10;
  width: 342px;
  height: 182px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  border-radius: 15px;
  font-weight: 800;
  font-size: 1.5em;
  background-color: white;
  margin: 4px;
}
.map-img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: transparent;
}
.map-img img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: transparent;
  
}
.submit-button-div {
  
  width: 75%;
  border-radius: 7px;
  height: auto;
  background-color: transparent;
  margin: 10px 0;
}
#submit-button {
  width: 100%;
  height: 45px !important;
  background-image: linear-gradient(to right, #f6a921, #cd2855);
  border-radius: 7px;
  border: none;
  font-size: 21px;
  letter-spacing: 2px;
  font-weight: 700;
  color: white;
}
}
