.social-box {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-right: 2%;
  background-color: transparent;
  opacity: 0;
    animation: moveUp 1s ease-in-out 0.5s forwards;
    animation-delay: 1,8s;
}
.box {
  margin: auto;
  margin: 5px 3px;
  height: 75px;
  width: 75px;

  border-radius: 25px;
  border-image: linear-gradient(to top right, #cd2855, #f6a921) 1;

  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box:hover {
  transform: scale(1.1);
  cursor: pointer;
}

@media screen and (max-width: 1073px) {

  .social-box {

margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: space-between;
    margin-right: 2%;
    background-color: transparent;
    opacity: 0;
    animation: moveUp 1s ease-in-out 0.5s forwards;
    animation-delay: 1,8s;
  }
  
}


@keyframes moveUp {
  0% {
    transform: translateY(+50px); /* Yukarıdan başlar */
    opacity: 0; /* Başlangıçta opaklık 0 */
  }
  100% {
    transform: translateY(0); /* Kendi konumuna gelir */
    opacity: 1; /* Opaklık 1 olur */
  }
}